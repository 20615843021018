export default `
<style>
.dialog-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.1);
}

.dialog-container {
  position: absolute;
  width: 800px;
  max-height: 80vh;
  background-color: #FAFAFA;
  box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.title {
  color: rgba(0,0,0,0.87);
  font-size: 24px;
  margin-bottom: 20px;
}

.form-container {
  flex: 1;
  overflow-y: auto;
  min-height: 0;
  margin-bottom: 20px;
}

.form-container > div {
  margin-bottom: 20px;
}

.buttons-container {
  display: flex;
}

.buttons-container > div:last-child {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

button {
  height: 36px;
  font-family: var(--font);
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--buttonColor);
  background-color: transparent;
  border-radius: 4px;
  border: none;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
}

button:hover {
  background-color: var(--buttonBackgroundHover);
}

button:focus {
  outline: none;
  background-color: var(--buttonBackgroundHover);
}

button[disabled] {
  opacity: 50%;
  cursor: default;
}

button[disabled]:hover {
  background-color: var(--buttonBackground);
}
</style>

<div class="root">
  <div class="dialog-backdrop">
    <div class="dialog-container">
      <div class="title">TITLE</div>
      <div class="form-container">
        <slot></slot>
      </div>
      <div class="buttons-container">
        <div>
          <button id="cancel">cancel</button>
          <button id="ok" class="primary" disabled>save</button>
        </div>
      </div>
    </div>
  </div>
</div>
`;
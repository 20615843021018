import dayjs from 'dayjs'
import { formatDecimal, roundNumber } from "./numberFunctions";
import { hasPercentageDeviated } from "./faultReportFunctions";
import { getComponentFaultTitle, getMainFaultTitle, getSubFaultTitle } from "./faultConstants";
import { faultGroupKey } from "./faultReportConstants";
import { TrendingDown, TrendingFlat, TrendingUp } from "@material-ui/icons";

export const MONTH_KEY_FORMAT = 'MM/YY';

export const IMPROVEMENT_TARGET_STATE = {
  SUGGESTION: 0,
  ACTIVE: 1,
  COMPLETED: 2,
  REMOVED: -1,
}

const formatPercentageValue = (value) => {
  const unformatted = value?.toString();
  const formatted = formatDecimal(unformatted, 2);

  return formatted ? formatted + ' %' : null;
}

const getRefKey = (item) => {
  if (item.source === 2) {
    return 'trafi';
  } else if (item.source === 1) {
    return item.chainId === 0 ? 'doris' : 'chain';
  } else {
    return 'site';
  }
}

export const faultGroupKeyToImprovementTargetRefType = (groupKey) => {
  if (groupKey === faultGroupKey.trafi) {
    return 'trafi';
  } else if (groupKey === faultGroupKey.doris) {
    return 'doris';
  } else if (groupKey === faultGroupKey.chain) {
    return 'chain';
  } else if (groupKey === faultGroupKey.total) {
    return 'site';
  }
}

export const getFaultTitle = (fault, languageId) => {
  if (Number.isInteger(fault.mainFaultId)) {
    if (Number.isInteger(fault.subFaultId)) {
      if (Number.isInteger(fault.componentFaultId)) {
        return getMainFaultTitle(fault, languageId) + ' - ' + getSubFaultTitle(fault, languageId) + ' - ' + getComponentFaultTitle(fault, languageId);
      } else {
        return getMainFaultTitle(fault, languageId) + ' - ' + getSubFaultTitle(fault, languageId);
      }
    } else {
      return getMainFaultTitle(fault, languageId);
    }
  }

  return null;
}

export const calculateImprovementTargetStatus = ({startValue, latestValue, trafiValue, severityLevel}) => {
  const targetValue = trafiValue;
  const refDiff = Math.max(0.001, Math.abs(startValue - targetValue));
  const latestDiff = Math.max(0.001, Math.abs(latestValue - targetValue));

  const trendingNow = 100 * refDiff / latestDiff - 100;
  const diffNow = targetValue > 0 ? (latestValue / targetValue * 100 - 100) : 0;

  const isImproving = trendingNow >= 30;
  const isDeclining = trendingNow <= -30;
  const isOkNow = ! hasPercentageDeviated(latestValue, targetValue, severityLevel);

  return {startValue, latestValue, targetValue, trendingNow, diffNow, isImproving, isDeclining, isOkNow};
}

export const formatImprovementTargetForList = (item, sites, t, languageId) => {
  const result = { ...item };

  if (result.mainFaultId !== null && result.mainFaultId !== undefined) {
    result.target = getFaultTitle(result, languageId);
  }

  if (! result.target && result.atjResult === 8) {
    result.target = t('improvementTargetView.rejectPercentage');
  }

  if (! result.target) {
    result.target = result.atjResult;
  }

  if (result.startDate) {
    result.dateShort = dayjs(result.startDate).format('DD.MM.YYYY');
  }

  if (result.completedAt) {
    result.completed = dayjs(result.completedAt).format('DD.MM.YYYY');
  }

  result.userName = result.userId ? result.userFirstName + ' ' + result.userLastName : null;
  result.supervisedByUserName = result.supervisedByUserId ? result.supervisedByUserFirstName + ' ' + result.supervisedByUserLastName : null;

  result.startValue = formatPercentageValue(result.startValue);
  result.latestValue = formatPercentageValue(result.latestValue);
  result.targetValue = formatPercentageValue(result.trafiValue);

  if (item.latestValue && item.trafiValue) {
    const info = calculateImprovementTargetStatus(item);

    result.status = <div>
      {info.isImproving ? <TrendingUp style={{color: "green", verticalAlign: "bottom", marginRight: 8}} /> : null}
      {info.isDeclining ? <TrendingDown style={{color: "red", verticalAlign: "bottom", marginRight: 8}}/> : null}
      {!info.isDeclining && !info.isImproving ? <TrendingFlat style={{color: "silver", verticalAlign: "bottom", marginRight: 8}}/> : null}
      {info.isOkNow ? <span style={{fontSize: "smaller", color: "green", fontWeight: "bold"}}>OK</span> : null}
    </div>;

    result.statusNumber = (info.isOkNow ? -4 : 0) + (info.isImproving ? 1 : 0) + (info.isDeclining ? 2 : 0);
  } else {
    result.statusNumber = 0;
  }

  const siteIds = result.siteIds ?? (result.siteId ? [result.siteId] : []);

  result.siteNames = siteIds?.length > 0 ? siteIds.map((id) => sites[id]?.name).filter(x => x).sort().join(', ') : t('allSites');

  return result;
}

export const formatImprovementTargetMonitoring = (monitoring) => {
  const data = {}
  const refs = {}

  const startDate = dayjs(monitoring.valuesStartDate);
  const endDate = dayjs(monitoring.valuesEndDate ?? null).endOf('month');

  const timeKeys = [];
  for (let month = startDate; month <= endDate; month = month.add(1, 'month')) {
    const timeKey = month.format('MM/YY');
    timeKeys.push(timeKey);
  }

  for (const item of monitoring.values) {
    const isFault = !! item.mainFaultId;

    if (item.userId) {
      const timeKey = item.month?.toString()?.padStart(2, '0') + '/' + item.year.toString().substring(2, 4);
      const faultKey = isFault ? `f.${item.mainFaultId}.${item.subFaultId}.${item.componentFaultId}.${item.faultId ?? 'x'}-l.${item.severityLevel ?? 'x'}` : `reject`;
      const lineKey = `${faultKey}-u.${item.userId}`;

      if (! data[lineKey]) {
        data[lineKey] = {
          chainId: item.chainId,
          siteId: item.siteId,
          fault: {
            mainFaultId: item.mainFaultId,
            subFaultId: item.subFaultId,
            componentFaultId: item.componentFaultId,
            severityLevel: item.severityLevel,
          },
          userId: item.userId,
          values: {},
        };
      }

      data[lineKey].values[timeKey] = {
        averageAge: item.averageAge,
        count: item.count,
        total: item.total,
        totalDrivingBan: item.totalDrivingBan,
        totalReject: item.totalReject,
      };
    } else {
      const timeKey = item.month?.toString()?.padStart(2, '0') + '/' + item.year.toString().substring(2, 4);
      const refKey = getRefKey(item);

      if (! refs[refKey]) {
        refs[refKey] = {
          chainId: item.chainId,
          source: item.source,
          values: {},
        };
      }

      refs[refKey].values[timeKey] = {
        averageAge: item.averageAge,
        count: item.count,
        total: item.total,
        totalDrivingBan: item.totalDrivingBan,
        totalReject: item.totalReject,
      };
    }
  }

  const r = {
    ...monitoring,
    times: timeKeys,
    values: Object.values(data),
    refData: refs,
  };

  return r;
}

export const buildImprovementTargetGraphData = (monitoringData) => {
  const data = monitoringData?.values?.at(0);

  if (data) {
    const isFault = (data.fault?.mainFaultId ?? null) !== null;

    let prev1 = null;
    let prev2 = null;
    const values = [];

    for(const at of monitoringData.times) {
      const atYear = '00' + at.substring(2, 5);
      const item = data.values[at];
      const refSite = monitoringData.refData.site?.values;
      const refChain = monitoringData.refData.chain?.values;
      const refDoris = monitoringData.refData.doris?.values;
      const refTrafi = monitoringData.refData.trafi?.values[at] ?? monitoringData.refData.trafi?.values[atYear];

      const value = {
        name: at,
        count: item ? (isFault ? item.count : item.totalReject) : 0,
        total: item ? item.total : 0,
        trafi: refTrafi && refTrafi.total >= 1 ? roundNumber((isFault ? refTrafi.count : refTrafi.totalReject) / refTrafi.total * 100, 2) : null,
      };

      if (prev2) {
        const faultCount = (value.count ?? 0) + (prev1?.count ?? 0) + (prev2?.count ?? 0)
        const faultTotal = (value.total ?? 0) + (prev1?.total ?? 0) + (prev2?.total ?? 0);
        const siteCount = isFault ? (refSite[at]?.count ?? 0) + (refSite[prev1.name]?.count ?? 0) + (refSite[prev2.name]?.count ?? 0) : (refSite[at]?.totalReject ?? 0) + (refSite[prev1.name]?.totalReject ?? 0) + (refSite[prev2.name]?.totalReject ?? 0)
        const siteTotal = (refSite[at]?.total ?? 0) + (refSite[prev1.name]?.total ?? 0) + (refSite[prev2.name]?.total ?? 0)
        const chainCount = isFault ? (refChain[at]?.count ?? 0) + (refChain[prev1.name]?.count ?? 0) + (refChain[prev2.name]?.count ?? 0) : (refChain[at]?.totalReject ?? 0) + (refChain[prev1.name]?.totalReject ?? 0) + (refChain[prev2.name]?.totalReject ?? 0)
        const chainTotal = (refChain[at]?.total ?? 0) + (refChain[prev1.name]?.total ?? 0) + (refChain[prev2.name]?.total ?? 0)
        const dorisCount = isFault ? (refDoris[at]?.count ?? 0) + (refDoris[prev1.name]?.count ?? 0) + (refDoris[prev2.name]?.count ?? 0) : (refDoris[at]?.totalReject ?? 0) + (refDoris[prev1.name]?.totalReject ?? 0) + (refDoris[prev2.name]?.totalReject ?? 0)
        const dorisTotal = (refDoris[at]?.total ?? 0) + (refDoris[prev1.name]?.total ?? 0) + (refDoris[prev2.name]?.total ?? 0)

        values.push({
          ...value,
          value: faultTotal > 0 ? roundNumber(faultCount / faultTotal * 100, 2) : null,
          site: refSite[at]?.total > 0 && siteTotal > 0 ? roundNumber(siteCount / siteTotal * 100, 2) : null,
          chain: refChain[at]?.total > 0 && chainTotal > 0 ? roundNumber(chainCount / chainTotal * 100, 2) : null,
          doris: refDoris[at]?.total > 0 && dorisTotal > 0 ? roundNumber(dorisCount / dorisTotal * 100, 2) : null,
        });
      }

      prev2 = prev1;
      prev1 = value;
    }

    const status = calculateImprovementTargetStatus(monitoringData);
    status.startDate = monitoringData.startDate;

    return {
      isFault,
      fault: isFault ? data.fault : null,
      values,
      userId: data.userId,
      monitoring: status,
    };
  }

  return null;
};

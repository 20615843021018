import dayjs from 'dayjs';

export const RESERVED = 'reserved';
export const CHECKED_IN = 'checkedIn';
export const IN_PROGRESS = 'inProgress';
export const ON_HOLD = 'onHold';
export const DONE = 'done';
export const CANCELLED = 'canceled';
export const ANNULLED = 'annulled';
export const JOB_STATUS = {
  [RESERVED]: 1,
  [CHECKED_IN]: 2,
  [IN_PROGRESS]: 3,
  [ON_HOLD]: 4,
  [DONE]: 5,
  [CANCELLED]: 6,
  [ANNULLED]: 7,
};

export const DRIVE_IN = 'driveIn';
export const FRONTDESK_RESERVATION = 'frontdeskReservation';
export const ONLINE_RESERVATION = 'onlineReservation';
export const RESERVATION_TYPES = new Map([
  [1, DRIVE_IN],
  [2, FRONTDESK_RESERVATION],
  [3, ONLINE_RESERVATION],
]);

export const BRAKE = 'brake';
export const ABSORBER = 'absorber';
export const CONSUMPTION = 'consumption';
export const EMISSION = 'emission';
export const OBD = 'obd';
export const LIMITER = 'limiter';
export const TYRE_GROOVE = 'tyreGroove';
export const GAS_LEAK = 'gasLeak';
export const HEADLIGHTS = 'headlights';
export const NOISE = 'noise';
export const MEASUREMENT_TYPES = [BRAKE, ABSORBER, EMISSION, OBD, TYRE_GROOVE, LIMITER, GAS_LEAK, HEADLIGHTS, NOISE];

// Fixed measurements.
export const IDENTIFICATION = 'identification';
export const INFORMATION = 'information';
export const RESULT = 'result';
export const FAULTS = 'faults';

export const NO_ODOMETER = 1;
export const FIVE_DIGIT_METER_IN_KILOMETERS = 2;
export const SIX_DIGIT_METER_IN_KILOMETERS = 3;
export const FIVE_DIGIT_METER_IN_MILES = 4;
export const SIX_DIGIT_METER_IN_MILES = 5;
export const ODOMETER_TYPES = [{
  label: 'kilometer',
  value: SIX_DIGIT_METER_IN_KILOMETERS,
}, {
  label: 'mile',
  value: SIX_DIGIT_METER_IN_MILES,
}, {
  label: 'fiveDigitMeterInKilometers',
  value: FIVE_DIGIT_METER_IN_KILOMETERS,
}, {
  label: 'fiveDigitMeterInMiles',
  value: FIVE_DIGIT_METER_IN_MILES,
}, {
  label: 'noOdometer',
  value: NO_ODOMETER,
}];

export const TODO = 'todo';
export const NOT_REQUIRED = 'notRequired';
export const SKIPPED = 'skipped';
export const MEASUREMENT_STATUS = {
  [TODO]: 1,
  [IN_PROGRESS]: 2,
  [DONE]: 3,
  [NOT_REQUIRED]: 4,
  [SKIPPED]: 5,
};

export const RPM = 'rpm';
export const HAS_SUPER_CHARGER = 'hasSuperCharger';
export const EMISSION_CLASS = 'emissionClass';
export const EMISSION_LEVEL = 'emissionLevel';
export const IS_PERFORMED = 'isPerformed';
export const ADDITIONAL_INFORMATION = 'additionalInformation';

export const EMISSIONS = 'emissions';
export const EMISSIONS_READ_AT = 'emissions.readAt';
export const EMISSIONS_IDLE_READ_AT = 'emissions.idleReadAt';
export const EMISSIONS_REV_READ_AT = 'emissions.revReadAt';
export const EMISSIONS_READ_ERROR_CODE = 'emissions.readErrorCode';
export const EMISSIONS_CO_IDLE = 'emissions.coidle';
export const EMISSIONS_HC_IDLE = 'emissions.hcidle';
export const EMISSIONS_O2_IDLE = 'emissions.o2idle';
export const EMISSIONS_CO2_IDLE = 'emissions.co2idle';
export const EMISSIONS_RPM_IDLE = 'emissions.rpmidle';
export const EMISSION_IDLE_FIELDS = [EMISSIONS_CO_IDLE, EMISSIONS_HC_IDLE, EMISSIONS_O2_IDLE, EMISSIONS_CO2_IDLE, EMISSIONS_RPM_IDLE];

export const EMISSIONS_CO_REV = 'emissions.corev';
export const EMISSIONS_HC_REV = 'emissions.hcrev';
export const EMISSIONS_O2_REV = 'emissions.o2rev';
export const EMISSIONS_CO2_REV = 'emissions.co2rev';
export const EMISSIONS_RPM_REV = 'emissions.rpmrev';
export const EMISSIONS_LAMBDA_REV = 'emissions.lambdarev';
export const EMISSION_REV_FIELDS = [EMISSIONS_CO_REV, EMISSIONS_HC_REV, EMISSIONS_O2_REV, EMISSIONS_CO2_REV, EMISSIONS_LAMBDA_REV, EMISSIONS_RPM_REV];

export const EMISSIONS_KVALUE = 'emissions.kvalue';
export const EMISSIONS_DESCRIPTION = 'emissions.description';
export const DIESEL_FIELDS = [EMISSIONS_KVALUE, EMISSIONS_DESCRIPTION];

export const OBD_INDICATOR = 'obd.indicator';
export const OBD_TEST = 'obd.test';
export const OBD_ERROR_CODES = 'obd.errorcodes'; // TODO: update to match backend data.
export const OBD_READ_AT = 'obd.readAt';
export const OBD_READ_ERROR_CODE = 'obd.readErrorCode';

export const CONSUMPTION_CHARGE_TOTAL = 'consumption.chargeTotal';
export const CONSUMPTION_DENIED = 'consumption.denied';
export const CONSUMPTION_FUEL_CHARGING = 'consumption.fuelCharging';
export const CONSUMPTION_FUEL_ENGINE_ON = 'consumption.fuelEngineOn';
export const CONSUMPTION_FUEL_TOTAL = 'consumption.fuelTotal';
export const CONSUMPTION_INFORMED = 'consumption.informed';
export const CONSUMPTION_KM_CHARGING = 'consumption.kmCharging';
export const CONSUMPTION_KM_ENGINE_OFF = 'consumption.kmEngineOff';
export const CONSUMPTION_KM_ENGINE_ON = 'consumption.kmEngineOn';
export const CONSUMPTION_KM_TOTAL = 'consumption.kmTotal';
export const CONSUMPTION_NOT_REQUIRED = 'consumption.notRequired';
export const CONSUMPTION_PLUGIN_HYBRID = 'consumption.pluginhybrid';
export const CONSUMPTION_PRINTOUT = 'consumption.printout';
export const CONSUMPTION_READ_AT = 'consumption.readAt';
export const CONSUMPTION_READ_ERROR_CODE = 'consumption.readErrorCode';
export const CONSUMPTION_SENT_AT = 'consumption.sentAt';
export const CONSUMPTION_SENT_RESULT_TYPE = 'consumption.sentResultType';
export const CONSUMPTION_TRAFICOM_ERROR_CODE = 'consumption.traficomErrorCode';
export const CONSUMPTION_VIN = 'consumption.vin';
export const CONSUMPTION_REGISTRATION = 'consumption.registration';

export const BRAKE_USAGE = 'brake.usage';
export const BRAKE_USAGE_STEERING = 'brake.usagesteering';
export const BRAKE_PARK = 'brake.park';
export const BRAKE_USAGE_DYNOLOCK = 'brake.usagedynolock';
export const BRAKE_PARK_DYNOLOCK = 'brake.parkdynolock';
export const BRAKE_USAGE_DECELARITION = 'brake.usagedeceleration';
export const BRAKE_PARK_FORCE = 'brake.parkforce';
export const BRAKE_DECELERATION = 'brake.deceleration';

export const ABSORBER_LEFT = 'absorber.left';
export const ABSORBER_RIGHT = 'absorber.right';
export const ABSORBER_AXLE_DIFF = 'absorber.axlediff';

export const HEADLIGHTS_TEST = 'headlights.test';

export const TYRE_GROOVE_DEPTH = 'tyregroove.depth';
export const TYRE_GROOVE_SEASON = 'tyregroove.season';
export const TYRE_GROOVE_LEFT = 'tyregroove.left';
export const TYRE_GROOVE_RIGHT = 'tyregroove.right';
export const TYRE_GROOVE_WINTER = 'tyregroove.winter';

export const LIMITER_DATE = 'limiter.date';
export const LIMITER_SPEED = 'limiter.speed';

export const GASLEAK_TEST = 'gasleak.test';

export const FIELD_DECIMAL_PLACES = {
  [EMISSIONS_CO_IDLE]: 2,
  [EMISSIONS_HC_IDLE]: 0,
  [EMISSIONS_O2_IDLE]: 2,
  [EMISSIONS_CO2_IDLE]: 1,
  [EMISSIONS_RPM_IDLE]: 0,
  [EMISSIONS_CO_REV]: 2,
  [EMISSIONS_HC_REV]: 0,
  [EMISSIONS_O2_REV]: 2,
  [EMISSIONS_CO2_REV]: 1,
  [EMISSIONS_RPM_REV]: 0,
  [EMISSIONS_LAMBDA_REV]: 3,
  [EMISSIONS_KVALUE]: 2,
  [BRAKE_USAGE]: 1,
  [BRAKE_PARK]: 1,
  [TYRE_GROOVE_DEPTH]: 1,
  [LIMITER_SPEED]: 0,
};

export const LAMBDA_MINIMUM = 0.970;
export const LAMBDA_MAXIMUM = 1.030;

export const AXLES = 'axles';
export const LEFT = 'left';
export const RIGHT = 'right';
export const VEHICLE_MASS = 'vehicleMass';
export const POSITION = 'position';

export const LINE_ID = 'lineId';
export const ODOMETER_READING = 'odometerReading';
export const ODOMETER_TYPE = 'odometerType';
export const FUEL_ID = 'fuelId';
export const FUEL = 'fuel';
export const VEHICLE_CLASS = 'vehicleClass';
export const VEHICLE_COLOR = 'vehicleColor';
export const COMMISSIONING_DAY = 'commissioningDay';
export const TYPE_APPROVAL_NUMBER = 'typeApprovalNumber';
export const VIN = 'vin';
export const TOTAL_MASS = 'totalMass';
export const AXLE_COUNT = 'axleCount';
export const AXLE_COUNT_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8];
export const EMISSION_DIRECTIVE_VERSION = 'emissionDirectiveVersion';
export const EMISSION_DIRECTIVE_OPTIONS = [0, 4, 5, 6];

// Measurement settings values.
export const DEVICE_ID = 'deviceId';
export const EMISSIONS_IDLE_MEASURED = 'emissionsIdleMeasured';
export const EMISSIONS_REV_MEASURED = 'emissionsRevMeasured';
export const PARKING_BRAKE_COUNT = 'parkingBrakeCount';
export const USAGE_BRAKES_TESTED = 'usageBrakesTested';
export const PARK_BRAKES_TESTED = 'parkBrakesTested';
export const MEASUREMENT_SETTINGS = 'measurementSettings';
export const MEASUREMENT_VALUES = 'measurementValues';
export const DISABLED_REASON = 'disabledReason';
export const NOT_PERFORMED_REASON_CODE = 'notPerformedReasonCode';
export const MEASUREMENT_NOT_REQUIRED = 'measurementNotRequired';
export const VALID_MEASUREMENT_CERTIFICATE = 'validMeasurementCertificate';
export const MEASUREMENT_DEVICE_OUT_OF_ORDER = 'measurementDeviceOutOfOrder';
export const OTHER_REASON = 'otherReason';
export const NOT_PERFORMED_REASON_CODES = new Map([
  [1, MEASUREMENT_NOT_REQUIRED],
  [2, VALID_MEASUREMENT_CERTIFICATE],
  [3, MEASUREMENT_DEVICE_OUT_OF_ORDER],
  [4, OTHER_REASON],
]);

export const COMPLETED = 'completed';

export const INTERRUPTED = 'interrupted';
export const ACCEPTED = 'accepted';
export const REJECTED = 'rejected';
export const REJECTED_DISQUALIFIED = 'rejectedDisqualified';
export const ANNULED = 'annuled';
export const ATJ_RESULTS = new Map([
  [1, INTERRUPTED],
  [2, ACCEPTED],
  [3, REJECTED],
  [4, ANNULED],
  [5, REJECTED_DISQUALIFIED],
]);

export const SCHEDULED_INSPECTION_ID = 1;
export const AFTER_INSPECTION_ID = 2;
export const REGISTRATION_INSPECTION_ID = 3;
export const CONDITION_INSPECTION_ID = 8;
export const INDIVIDUAL_APPROVAL_ID = 9;
export const SOSO_MAK_JOB_TYPES = [SCHEDULED_INSPECTION_ID, AFTER_INSPECTION_ID];

export const SCHEDULED_INSPECTION = 'scheduledInspection';
export const AFTER_INSPECTION = 'afterInspection';
export const REGISTRATION_INSPECTION = 'registrationInspection';
export const CHANGE_INSPECTION = 'changeInspection';
export const CONTROL_INSPECTION = 'controlInspection';
export const MEASUREMENT = 'measurement';
export const CHECK_UP = 'checkUp';
export const CONDITION_INSPECTION = 'conditionInspection';
export const INDIVIDUAL_APPROVAL = 'individualApproval';
export const EXPORT_INSPECTION = 'exportInspection';
export const ENGAGEMENT_INSPECTION = 'engagementInspection';
export const SUBSTANCE_INSPECTION = 'substanceInspection';
export const BASE_JOB_TYPES = new Map([
  [1, SCHEDULED_INSPECTION],
  [2, AFTER_INSPECTION],
  [3, REGISTRATION_INSPECTION],
  [4, CHANGE_INSPECTION],
  [5, CONTROL_INSPECTION],
  [6, MEASUREMENT],
  [7, CHECK_UP],
  [8, CONDITION_INSPECTION],
  [9, INDIVIDUAL_APPROVAL],
  [10, EXPORT_INSPECTION],
  [11, ENGAGEMENT_INSPECTION],
  [12, SUBSTANCE_INSPECTION],
]);

// check how job.baseJobType is calculated in create/update job
// update job.baseJobTypeId on old jobs


// Inspection history filters.
export const REG_NUMBER_OR_VIN = 'regNumberOrVin';
export const START_TIME = 'startTime';
export const END_TIME = 'endTime';
export const BASE_JOB_TYPE_ID = 'baseJobTypeId';
export const ASSIGNED_USER_ID = 'assignedUserId';
export const ALL_JOB_TYPES = 'allJobTypes';
export const ATJ_RESULT = 'atjResult';
export const ALL_JOB_RESULTS = 'allJobResults';
export const ALL_USERS = 'allUsers';
export const DEFAULT_HISTORY_FILTERS = {
  [REG_NUMBER_OR_VIN]: '',
  [START_TIME]: dayjs().startOf('day').format(),
  [END_TIME]: dayjs().endOf('day').format(),
  [ATJ_RESULT]: ALL_JOB_RESULTS,
  [BASE_JOB_TYPE_ID]: ALL_JOB_TYPES,
  [ASSIGNED_USER_ID]: ALL_USERS,
};

// Surveillance type constants
export const REGISTRATION_DOCUMENT = 'registrationDocument';
export const CHANGE_DOCUMENT = 'changeDocument';
export const SCHEDULED_DOCUMENT = 'scheduledDocument';
export const SCHEDULED_TECHNICAL = 'scheduledTechnical';
export const SCHEDULED_DOCUMENT_AND_TECHNICAL = 'scheduledDocumentAndTechnical';
export const ALL_SURVEILLANCE_TYPES = 'allSurveillanceTypes';

export const SURVEILLANCE_TYPE_REGISTRATION_DOCUMENT = '1';
export const SURVEILLANCE_TYPE_CHANGE_DOCUMENT = '2';
export const SURVEILLANCE_TYPE_SCHEDULED_DOCUMENT = '3';
export const SURVEILLANCE_TYPE_SCHEDULED_TECHNICAL = '4';
export const SURVEILLANCE_TYPE_SCHEDULED_DOCUMENT_AND_TECHNICAL = '5';

export const SURVEILLANCE_TYPE_IDS = [
  SURVEILLANCE_TYPE_REGISTRATION_DOCUMENT,
  SURVEILLANCE_TYPE_CHANGE_DOCUMENT,
  SURVEILLANCE_TYPE_SCHEDULED_DOCUMENT,
  SURVEILLANCE_TYPE_SCHEDULED_TECHNICAL,
  SURVEILLANCE_TYPE_SCHEDULED_DOCUMENT_AND_TECHNICAL,
];

export const SURVEILLANCE_TYPES = new Map([
  [SURVEILLANCE_TYPE_SCHEDULED_TECHNICAL, SCHEDULED_TECHNICAL],
  [SURVEILLANCE_TYPE_SCHEDULED_DOCUMENT, SCHEDULED_DOCUMENT],
  [SURVEILLANCE_TYPE_SCHEDULED_DOCUMENT_AND_TECHNICAL, SCHEDULED_DOCUMENT_AND_TECHNICAL],
  [SURVEILLANCE_TYPE_REGISTRATION_DOCUMENT, REGISTRATION_DOCUMENT],
  [SURVEILLANCE_TYPE_CHANGE_DOCUMENT, CHANGE_DOCUMENT],
]);

// Surveillance history filters
export const VEHICLE_ID = 'vehicleId';
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
export const SURVEILLANCE_TYPE = 'surveillanceType';
export const JOB_ASSIGNED_USER_ID = 'jobAssignedUserId';
export const DEFAULT_SURVEILLANCE_FILTERS = {
  [START_DATE]: dayjs().subtract(2, 'month').startOf('day').format(),
  [END_DATE]: dayjs().endOf('day').format(),
  [SURVEILLANCE_TYPE]: ALL_SURVEILLANCE_TYPES,
  [JOB_ASSIGNED_USER_ID]: ALL_USERS,
  [VEHICLE_ID]: '',
};

// Inspection result fields.
export const USER_ID = 'userId';
export const ATJ_INTERRUPTED_REASON_CODE = 'atjInterruptedReasonCode';
export const ATJ_DISQUALIFIED_REASON_CODE = 'atjDisqualifiedReasonCode';
export const DO_NOT_UPDATE_ATJ = 'doNotUpdateAtj';
export const OUTSIDE_SITE = 'outsideSite';
export const RESULT_DESCRIPTION = 'resultDescription';
export const RESULT_SEND = 'sendResult';
export const RESULT_SEND_CONTENT_TYPE = 'contentType';
export const RESULT_SEND_UPDATED_AT = 'updatedAt';
export const RESULT_SEND_EMAIL_ADDRESS = 'email';
export const RESULT_SEND_PHONE_NUMBER = 'phone';
export const CERTIFICATE_NUMBER = 'certificateNumber';
export const VALIDITY_DATE = 'validityDate';
export const SET_NEW_VAK_EXTENSION_DATE = 'setNewVakExtensionDate';
export const VAK_EXTENSION_DATE = 'vakExtensionDate';
export const NEW_VAK_EXTENSION_DATE = 'newVakExtensionDate';
export const ATJ_PRINTER_ID = 'atjPrinterId';
export const PRINTER_ID = 'printerId';
export const COPIES = 'copies';
export const PRINT_LANGUAGE = 'printLanguage';
export const PRINT_IMAGES = 'printImages';
export const PRINT_USE_DORIS_SITE_PRINT = 'printWithDorisSitePrint';
export const REPAIR_SHOP_ADDRESS = 'repairShopAddress';
export const REPAIR_DEADLINE_AT = 'repairDeadlineAt';
export const REPAIR_DEADLINE_AT_DATE = 'repairDeadlineAtDate';
export const REPAIR_DEADLINE_AT_TIME = 'repairDeadlineAtTime';
export const DRIVING_BAN_CODE = 'drivingBanCode';
export const AFTER_INSPECTION_DATE = 'afterInspectionDate';
export const AFTER_INSPECTION_PRICE = 'afterInspectionPrice';
export const AFTER_INSPECTION_PRODUCT_ID = 'afterInspectionProductId';
export const AFTER_INSPECTION_PRODUCTS = 'afterInspectionProducts';
export const SECOND_USER_ID = 'secondUserId';

// Inspection results.
export const DRIVING_BAN = 'drivingBan';
export const INSPECTION_RESULTS = new Map([
  [1, ACCEPTED],
  [2, REJECTED],
  [3, DRIVING_BAN],
  [4, INTERRUPTED],
]);

// Inspection result send content types
export const RESULT_SEND_CONTENT_TYPE_NONE = 0;
export const RESULT_SEND_CONTENT_TYPE_CERTIFICATE = 1;

// ATJ disqualified reasons.
export const TECHNICAL_FAULT = 'technicalFault';
export const SIGNIFICANT_HARM_TO_ENVIRONMENT = 'significantHarmToEnvironment';
export const NOT_INSPECTED = 'notInspected';
export const VEHICLE_STRUCTURE_MODIFIED = 'vehicleStructureModified';
export const ATJ_DISQUALIFIED_REASONS = new Map([
  [1, TECHNICAL_FAULT],
  [2, SIGNIFICANT_HARM_TO_ENVIRONMENT],
  [3, NOT_INSPECTED],
  [4, VEHICLE_STRUCTURE_MODIFIED],
]);

// ATJ interrupted reasons.
export const INVALID_TECHNICAL_INFORMATION = 'invalidTechnicalInformation';
export const SITE_DEVICE_FAULT = 'siteDeviceFault';
export const INFORMATION_SYSTEM_ERROR = 'informationSystemError';
export const OVERDUE_PAYMENTS = 'overduePayments';
export const VEHICLE_MODIFIED = 'vehicleModified';
export const VEHICLE_NOT_IDENTIFIED = 'vehicleNotIdentified';
export const VEHICLE_NOT_INSURED = 'vehicleNotInsured';
export const VEHICLE_NOT_INSPECTED = 'vehicleNotInspected';
export const ATJ_INTERRUPTED_REASONS = new Map([
  [1, INVALID_TECHNICAL_INFORMATION],
  [2, SITE_DEVICE_FAULT],
  [3, INFORMATION_SYSTEM_ERROR],
  [4, OTHER_REASON],
  [5, OVERDUE_PAYMENTS],
  [6, VEHICLE_MODIFIED],
  [7, VEHICLE_NOT_IDENTIFIED],
  [8, VEHICLE_NOT_INSURED],
  [9, VEHICLE_NOT_INSPECTED],
]);

// Prefixes for determining fuel type.
export const DIESEL_BASED_FUEL_PREFIXES = ['Diesel', 'Biodiesel', 'Polttoöljy'];
export const GASEOUS_FUEL_PREFIXES = ['Kaasu', 'LPG', 'CNG', 'L-ryhmän maakaasu', 'HL-ryhmän maakaasu', 'Biometaani', 'LNG', 'H-ryhmän maakaasu'];

// Tyre groove options.
export const WINTER = 'winter';
export const SUMMER = 'summer';

// Condition inspection constants.
export const RATING_SCALES = [2, 3, 4, 5, 6];
export const RATING_SCALE_STEPS = [1, 2, 3, 4, 5, 6];
export const SURFACE_DAMAGE_MEASUREMENT_ID = 100;

export const TIRES_MEASUREMENT_ID = 171;
export const TIRE_TREAD_DEPTH_MEASUREMENT_ID = 173;
export const WINTER_TIRE_TREAD_DEPTH_MEASUREMENT_ID = 174;
export const SUMMER_TIRE_TREAD_DEPTH_MEASUREMENT_ID = 175;
export const WHEELS_MEASUREMENT_ID = 176;

export const COOLANT_FREEZING_POINT_ID = 239;
export const BRAKE_FLUID_BOILING_POINT_ID = 297;

export const BRAKE_DISCS = 298;
export const BRAKE_PADS = 299;

export const SERVICES_ID = 493;
export const SERVICE_BOOK_ID = 494;
export const INSPECTIONS_ID = 495;

export const ELECTRIC_CAR_BATTERY_TEST_ID = 552;
export const GAS_TANK_INSPECTION_DATE_ID = 554;

// The lower step value the worse the condition.
export const RATING_SCALE_TO_STEPS = {
  2: [5, 2],
  3: [5, 4, 2],
  4: [5, 4, 3, 2],
  5: [5, 4, 3, 2, 1],
  6: [6, 5, 4, 3, 2, 1],
};

// Measurements using a custom input field. Only number is supported at the moment.
export const CUSTOM_INPUT_MEASUREMENTS = [
  COOLANT_FREEZING_POINT_ID,
  BRAKE_FLUID_BOILING_POINT_ID,
  SERVICE_BOOK_ID,
  GAS_TANK_INSPECTION_DATE_ID,
];

export const RESULT_REJECTED_NO_FAULTS = 0;
export const RESULT_REJECTED_FAULT_WITH_ACCEPTED = 1;
export const RESULT_MORE_THAN_THREE_REPAIR_RECOMMENDATION_WITH_ACCEPTED = 2;
export const RESULT_LESS_THAN_OR_EQUAL_THREE_REPAIR_RECOMMENDATION_WITH_REJECTED = 3;

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { updateLastSiteId } from '../../reducers/authReducer';
import { setSelectedSiteId } from '../../reducers/siteReducer';
import SmallSelectField from '../common/SmallSelectField';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 305,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Jost, sans-serif',
  },
  topContainer: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${theme.palette.borderGrey.main}`,
  },
  userContainer: {
    display: 'flex',
    fontSize: 16,
    color: theme.palette.black.main,
    alignItems: 'center',
    lineHeight: '23px',
    marginBottom: 20,
  },
  userImage: {
    backgroundColor: theme.palette.green.main,
    width: 61,
    height: 61,
    borderRadius: '50%',
    marginRight: 20,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  button: {
    height: 48,
    borderBottom: `1px solid ${theme.palette.borderGrey.main}`,
    fontWeight: 500,
    color: theme.palette.textDarkGrey.main,
    fontSize: 16,
    paddingLeft: 25,
    paddingRight: 10,
    borderLeft: '5px solid transparent',
    justifyContent: 'flex-start',
  },
  activeButton: {
    backgroundColor: theme.palette.primaryLight.main,
    borderLeftColor: theme.palette.primary.main,
    borderBottomColor: 'transparent',
    color: theme.palette.primary.main,
  },
  logoutButton: {
    height: 48,
    borderTop: `1px solid ${theme.palette.borderGrey.main}`,
    fontWeight: 500,
    color: theme.palette.textDarkGrey.main,
    fontSize: 16,
    paddingLeft: 25,
    paddingRight: 10,
    borderLeft: '5px solid transparent',
    justifyContent: 'flex-start',
  },
}));

const RECEPTION = 'reception';
const WORK_QUEUE = 'workQueue';
const INSPECTION_HISTORY = 'inspectionHistory';
const QUALITY_CONTROL = 'qualityControl';
const ATJ_PRINTS = 'atjPrints';
const HAYNES_DATA = 'haynesInfo';
const BULLETIN = 'bulletin';
const MENU_ITEMS = [
  { name: RECEPTION, isExternal: true },
  { name: WORK_QUEUE, isExternal: false },
  { name: INSPECTION_HISTORY, isExternal: false },
  { name: QUALITY_CONTROL, isExternal: false },
  { name: ATJ_PRINTS, isExternal: false },
  { name: HAYNES_DATA, isExternal: false },
  { name: BULLETIN, isExternal: true },
];

function MobileNavBar({ open, onClose, onOpen, showSiteSelect, handleLogout }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const sites = useSelector(state => state.sites.sites);
  const selectedSiteId = useSelector(state => state.sites.selectedSiteId);
  const user = useSelector(state => state.auth.user);
  const selectedChainId = useSelector(state => state.chains.selectedChainId);

  const handleSiteChange = (event) => {
    event.stopPropagation();
    const siteId = event.target.value;
    dispatch(setSelectedSiteId(siteId));
    dispatch(updateLastSiteId({ lastSiteId: siteId }));
  };

  return (
    <SwipeableDrawer
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      disableSwipeToOpen={false}
      hysteresis={0.25}
    >
      <div
        className={classes.drawer}
        role='presentation'
        onKeyDown={onClose}
      >
        <div className={classes.topContainer}>
          <div className={classes.userContainer}>
            <div className={classes.userImage} />
            <div>{user ? user.name : ''}</div>
          </div>
          {showSiteSelect && (
            <div className={classes.selectContainer}>
              <SmallSelectField
                value={selectedSiteId || ''}
                onChange={handleSiteChange}
                options={sites}
              />
            </div>
          )}
        </div>
        <div className={classes.buttonsContainer}>
          {MENU_ITEMS.map((item) => item.isExternal ? (
            <ButtonBase
              key={item.name}
              className={classes.button}
              component='a'
              href={`${process.env.REACT_APP_KAPA_BASE_URL}/${item.name}?c=${selectedChainId}&s=${selectedSiteId}`}
            >
              {t(item.name)}
            </ButtonBase>
          ) : (
            <ButtonBase
              key={item.name}
              className={classes.button}
              activeClassName={classes.activeButton}
              component={NavLink}
              to={`/${item.name}`}
              onClick={onClose}
            >
              {t(item.name)}
            </ButtonBase>
          ))}
          <ButtonBase
            key={'help'}
            className={classes.button}
            component='a'
            href={'https://tuki.idealinspect.fi/kb'}
            target='_blank'
          >
            {t('help')}
          </ButtonBase>
        </div>
        <ButtonBase
          className={classes.logoutButton}
          onClick={handleLogout}
        >
          {t('logout')}
        </ButtonBase>
      </div>
    </SwipeableDrawer>
  );
}

MobileNavBar.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  showSiteSelect: PropTypes.bool,
  handleLogout: PropTypes.func.isRequired,
};

export default MobileNavBar;
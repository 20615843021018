import editPrimaryImage from '../../../assets/icons/edit_primary.svg';
import addPrimaryImage from '../../../assets/icons/add_primary.svg';
import arrowDownImage from '../../../assets/icons/arrow_down.svg';

export default
`<div id="site-info-section">
  <div id="form-container" class="form-container">
    <div class="form-column">
      <div id="basic-info-title" class="secondary-title"></div>

      <div>
        <div id="name-label" class="input-label"></div>
        <input id="name" />
      </div>

      <div>
        <div id="sokuIdentifier-label" class="input-label"></div>
        <input id="sokuIdentifier" />
      </div>

      <div>
        <div id="selfServiceAtjIdentifier-label" class="input-label"></div>
        <input id="selfServiceAtjIdentifier" />
      </div>

      <div>
        <div id="siteGroupId-label" class="input-label"></div>
        <div class="select-container">
          <div id="siteGroupId-backdrop" class="dropdown-backdrop collapsed"></div>
          <div id="siteGroupId" class="select" fullWidth>
            <div id="siteGroupId-text" class="select-text"></div>
            <img id="select-icon" class="select-icon" src="${arrowDownImage}" />
            <div id="siteGroupId-dropdown" class="dropdown collapsed"></div>
          </div>
        </div>
      </div>

      <div>
        <div id="logoBase64-label" class="input-label"></div>
        <div id="logoBase64" class="logo-input">
          <img id="logoBase64-img" />
        </div>
        <input id="logoBase64-input" type="file" accept="image/*" class="file-input" />
        <button id="logoBase64-button"></button>
      </div>
    </div>

    <div class="form-column">
      <div id="company-info-title" class="secondary-title"></div>

      <div>
        <div id="companyId-label" class="input-label"></div>
        <div class="company-select-container">
          <div class="select-container">
            <div id="companyId-backdrop" class="dropdown-backdrop collapsed"></div>
            <div id="companyId" class="select">
              <div id="companyId-text" class="select-text"></div>
              <img class="select-icon" src="${arrowDownImage}" />
              <div id="companyId-dropdown" class="dropdown collapsed"></div>
            </div>
          </div>
          <button id="add-company-button" small>
            <img src="${addPrimaryImage}" />
          </button>
          <button id="edit-company-button" small disabled>
            <img src="${editPrimaryImage}" />
          </button>
        </div>
      </div>

      <div>
        <div id="companyBusinessIdentifier-label" class="input-label"></div>
        <div id="companyBusinessIdentifier"></div>
      </div>

      <div>
        <div id="companySokuIdentifier-label" class="input-label"></div>
        <div id="companySokuIdentifier"></div>
      </div>
    </div>
  </div>
</div>
`;
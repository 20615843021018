import dayjs from 'dayjs'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit/dist/index';
import { sendChainRequest, sendRequest } from '../api/api';
import { formatImprovementTargetMonitoring } from '../lib/improvementTargetFunctions';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const isOutdatedRequest = (state, requestId) => {
  const pendingRequestId = state.improvementTarget.requestId;

  return pendingRequestId && pendingRequestId !== requestId;
}

const isOutdatedListRequest = (state, requestId) => {
  const pendingRequestId = state.improvementTarget.listRequestId;

  return pendingRequestId && pendingRequestId !== requestId;
}

const isOutdatedMonitoringRequest = (state, requestId) => {
  const pendingRequestId = state.improvementTarget.monitoringRequestId;

  return pendingRequestId && pendingRequestId !== requestId;
}

export const fetchImprovementTargetList = createAsyncThunk('improvementTarget/fetchImprovementTargetList', async ({chainId, filters, isImmediate}, { getState, requestId }) => {
  if (! isImmediate) {
    await sleep(500);
  }

  if (isOutdatedListRequest(getState(), requestId)) {
    return null;
  }

  const searchCriteria = filters ?? getState().improvementTarget.criteria

  const query = {
    procName: 'GetImprovementTargetList',
    data: { ...searchCriteria, chainId },
  };

  const state = getState();

  const listData = await sendRequest(chainId, query, 'POST', state.auth.token);

  if (isOutdatedListRequest(getState(), requestId)) {
    return null;
  }

  return {
    criteria: searchCriteria,
    list: listData,
  };
});

export const fetchImprovementTargetMonitoring = createAsyncThunk('improvementTarget/fetchImprovementTargetMonitoring', async ({criteria, isImmediate}, { getState, requestId }) => {
  if (! isImmediate) {
    await sleep(500);
  }

  if (isOutdatedMonitoringRequest(getState(), requestId)) {
    return null;
  }

  const criteriaEndDate = dayjs(criteria.endDate ?? null);
  const maxEndDate = dayjs().subtract(1, 'day');
  const endDate = criteriaEndDate.isAfter(maxEndDate) ? maxEndDate : criteriaEndDate;
  const startDate = criteria.startDate ? dayjs(criteria.startDate) : dayjs().startOf('month');

  const searchCriteria = {...criteria, startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD')};
  const state = getState();
  const monitoring = await sendChainRequest(criteria.chainId, 'POST', '/quality-control/improvement-target/monitoring', '', searchCriteria, state.auth.token);

  if (isOutdatedMonitoringRequest(getState(), requestId)) {
    return null;
  }

  return {
    monitoring,
  };
});

export const fetchImprovementTarget = createAsyncThunk('improvementTarget/fetchImprovementTarget', async ({ chainId, siteId, improvementTargetId }, { getState, requestId }) => {
    if (isOutdatedRequest(getState(), requestId)) {
      return null;
    }

    const query = {
      procName: 'GetImprovementTarget',
      data: { id: improvementTargetId },
    };

    const state = getState();
    const improvementTarget = await sendRequest(chainId, query, 'POST', state.auth.token);

    if (isOutdatedRequest(getState(), requestId)) {
      return null;
    }

    return { ...improvementTarget };
  }
);

export const updateImprovementTarget = createAsyncThunk(
  'improvementTarget/updateImprovementTarget',
  async ({ chainId, payload }, API) => {
    const uriPostfix = payload.id ? `/${payload.id}` : '';

    const transformedPayload = {
      ...payload,
    };

    if (! transformedPayload.id) {
      delete transformedPayload.id;
    }

    const state = API.getState();
    const improvementTarget = await sendChainRequest(chainId, 'POST', '/quality-control/improvement-target' + uriPostfix, '', transformedPayload, state.auth.token);

    return { ...improvementTarget };
  }
);

export const markImprovementTargetsCompleted = createAsyncThunk(
  'improvementTarget/markImprovementTargetsCompleted',
  async ({ chainId, ids }, API) => {
    const state = API.getState();
    await sendChainRequest(chainId, 'POST', '/quality-control/improvement-target/mark-completed', '', ids, state.auth.token);

    return null;
  }
);

export const acceptImprovementTargetSuggestions = createAsyncThunk(
  'improvementTarget/acceptImprovementTargetSuggestions',
  async ({ chainId, ids }, API) => {
    const state = API.getState();
    await sendChainRequest(chainId, 'POST', '/quality-control/improvement-target/accept-suggestions', '', ids, state.auth.token);

    return null;
  }
);

export const createImprovementTargetSuggestions = createAsyncThunk(
  'improvementTarget/createImprovementTargetSuggestions',
  async ({ chainId, suggestions }, API) => {
    const state = API.getState();

    return await sendChainRequest(chainId, 'POST', '/quality-control/improvement-target/create-suggestions', '', suggestions, state.auth.token);
  }
);

const improvementTargetSlice = createSlice({
  name: 'improvementTarget',
  initialState: {
    criteria: {},
    current: null,
    list: null,
    listFilters: {},
    monitoring: null,
    newSuggestionIds: null,
    isRequesting: false,
  },
  reducers: {
    resetCurrentImprovementTarget: (state, action) => {
      state.current = null;
      state.monitoring = null;
    },
    setImprovementTargetListFilters: (state, action) => {
      state.listFilters = {...action.payload};
    },
  },
  extraReducers: {
    [fetchImprovementTargetList.pending]: (state, { meta }) => {
      state.list = null;
      state.listRequestId = meta.requestId;
      state.monitoring = null;
      state.isRequesting = true;
    },
    [fetchImprovementTargetList.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.list = payload?.list ?? [];
        state.listRequestId = null;
        state.isRequesting = Boolean(state.requestId || state.listRequestId || state.monitoringRequestId);
      }
    },
    [fetchImprovementTargetList.rejected]: (state) => {
      state.listRequestId = null;
      state.isRequesting = Boolean(state.requestId || state.listRequestId || state.monitoringRequestId);
    },
    [fetchImprovementTargetMonitoring.pending]: (state, { meta }) => {
      state.monitoring = null;
      state.monitoringRequestId = meta.requestId;
      state.isRequesting = true;
    },
    [fetchImprovementTargetMonitoring.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.monitoring = formatImprovementTargetMonitoring(payload?.monitoring);
        state.monitoringRequestId = null;
        state.isRequesting = Boolean(state.requestId || state.listRequestId || state.monitoringRequestId);
      }
    },
    [fetchImprovementTargetMonitoring.rejected]: (state) => {
      state.monitoringRequestId = null;
      state.isRequesting = Boolean(state.requestId || state.listRequestId || state.monitoringRequestId);
    },
    [fetchImprovementTarget.pending]: (state, { meta }) => {
      state.current = null;
      state.monitoring = null;
      state.requestId = meta.requestId;
      state.isRequesting = true;
    },
    [fetchImprovementTarget.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.current = payload ?? {};
        state.requestId = null;
        state.isRequesting = Boolean(state.requestId || state.listRequestId || state.monitoringRequestId);
      }
    },
    [fetchImprovementTarget.rejected]: (state) => {
      state.requestId = null;
      state.isRequesting = Boolean(state.requestId || state.listRequestId || state.monitoringRequestId);
    },
    [updateImprovementTarget.pending]: (state) => {
      state.isRequesting = true;
    },
    [updateImprovementTarget.fulfilled]: (state, { payload }) => {
      state.current = payload ?? {};
      state.isRequesting = Boolean(state.requestId || state.listRequestId || state.monitoringRequestId);
    },
    [updateImprovementTarget.rejected]: (state) => {
      state.isRequesting = Boolean(state.requestId || state.listRequestId || state.monitoringRequestId);
    },
    [acceptImprovementTargetSuggestions.pending]: (state) => {
      state.isRequesting = true;
    },
    [acceptImprovementTargetSuggestions.fulfilled]: (state, { payload }) => {
      state.list = null;
      state.current = {};
      state.isRequesting = Boolean(state.requestId || state.listRequestId || state.monitoringRequestId);
    },
    [acceptImprovementTargetSuggestions.rejected]: (state) => {
      state.isRequesting = Boolean(state.requestId || state.listRequestId || state.monitoringRequestId);
    },
    [createImprovementTargetSuggestions.pending]: (state) => {
      state.isRequesting = true;
      state.newSuggestionIds = null;
    },
    [createImprovementTargetSuggestions.fulfilled]: (state, { payload }) => {
      state.newSuggestionIds = payload ?? [];
      state.list = null;
      state.current = {};
      state.isRequesting = Boolean(state.requestId || state.listRequestId || state.monitoringRequestId);
    },
    [createImprovementTargetSuggestions.rejected]: (state) => {
      state.newSuggestionIds = [];
      state.isRequesting = Boolean(state.requestId || state.listRequestId || state.monitoringRequestId);
    },
    [markImprovementTargetsCompleted.pending]: (state) => {
      state.isRequesting = true;
    },
    [markImprovementTargetsCompleted.fulfilled]: (state, { payload }) => {
      state.list = null;
      state.current = {};
      state.isRequesting = Boolean(state.requestId || state.listRequestId || state.monitoringRequestId);
    },
    [markImprovementTargetsCompleted.rejected]: (state) => {
      state.isRequesting = Boolean(state.requestId || state.listRequestId || state.monitoringRequestId);
    },
  },
});

export const {
  resetCurrentImprovementTarget,
  setImprovementTargetListFilters,
} = improvementTargetSlice.actions;

export default improvementTargetSlice.reducer;
